import React, { useEffect } from "react";
import ModalDialog from "../../../layout/modal-dialog";
import InputControlled from "../../../layout/input-controlled";
import { useQuery } from "@tanstack/react-query";
import helpDocsTemplateItemsService from "../../../services/help-docs-template-items.service";
import PrintPre from "../../../layout/print-pre";
import CheckBoxNeoGenControlled from "../../../layout/checkbox-controlled";
import helpDocsService from "../../../services/help-docs.service";
import helpDocsTemplateService, { HelpDocTemplate } from "../../../services/help-docs-template.service";

function AddTemplate({
    show,
    close,
    template,
}: {
    show: boolean;
    close: () => void;
    template?: HelpDocTemplate;
}): JSX.Element {
    const [templateName, setTemplateName] = React.useState(template ? template.name : "");
    const [templateItems, setTemplateItems] = React.useState<number[]>([]);
    const helpDocsTemplateItemsQuery = useQuery(["help-docs-template-items", template?.id], async () => {
        const response = await helpDocsTemplateItemsService.getFilteredWhere({ templateId: template?.id });
        if (response) {
            return response.data;
        }
    });
    const helpDocsQuery = useQuery(["help-docs"], async () => {
        const response = await helpDocsService.getAll();
        if (response) {
            return response.data;
        }
    });
    useEffect(() => {
        if (template) {
            setTemplateItems(
                helpDocsTemplateItemsQuery.data
                    ?.filter((item) => item.templateId === template.id)
                    .map((item) => item.helpDocId) ?? [],
            );
            // Set Template Name
            setTemplateName(template.name);
        }
    }, [helpDocsTemplateItemsQuery.data, template]);
    if (helpDocsTemplateItemsQuery.isLoading && helpDocsQuery.isLoading) {
        return <div>Loading...</div>;
    }
    return (
        <ModalDialog
            close={close}
            show={show}
            size="lg"
            title={template ? "Edit Template" : "Add Template"}
            okAction={async () => {
                if (template) {
                    // Edit Template
                    // alert("Edit Template");
                    // Update the template name
                    helpDocsTemplateService.update(template.id, { name: templateName });
                    // Add or remove items from the template
                    const itemsToAdd = templateItems.filter(
                        (item) => !helpDocsTemplateItemsQuery.data?.some((i) => i.helpDocId === item),
                    );
                    const itemsToRemove = helpDocsTemplateItemsQuery.data?.filter(
                        (item) => !templateItems.some((i) => i === item.helpDocId),
                    );
                    itemsToAdd.forEach((item) => {
                        helpDocsTemplateItemsService.create({ helpDocId: item, templateId: template.id });
                    });
                    itemsToRemove?.forEach((item) => {
                        helpDocsTemplateItemsService.deleteByID(item.id);
                    });
                } else {
                    // Add Template
                    // alert("Add Template");
                    const newTemplate = await helpDocsTemplateService.create({
                        name: templateName,
                        helpDocs: templateItems,
                    });
                    if (!newTemplate) {
                        // Add items to the template
                        templateItems.forEach((item) => {
                            helpDocsTemplateItemsService.create({
                                helpDocId: item,
                                templateId: (newTemplate as any)?.id,
                            });
                        });
                    }
                }
            }}
        >
            <>
                {/* <PrintPre>{template}</PrintPre> */}
                <InputControlled
                    label="Template Name"
                    type="text"
                    className="form-control"
                    value={templateName ?? ""}
                    onChange={(e) => setTemplateName(e)}
                />
                {/* Template Item List Checkboxes */}
                <div className="grid grid-cols-3">
                    {/* <PrintPre>{templateItems}</PrintPre> */}
                    {helpDocsQuery.data?.map((item) => (
                        <div key={item.id}>
                            <CheckBoxNeoGenControlled
                                label={item.title ?? "No Title"}
                                id={(item.id ?? "").toString()}
                                value={templateItems.includes(item.id ?? -1)}
                                name={""}
                                setValue={(value) => {
                                    if (value) {
                                        setTemplateItems([...templateItems, item.id ?? -1]);
                                    } else {
                                        setTemplateItems(templateItems.filter((i) => i !== item.id));
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </>
        </ModalDialog>
    );
}

export default AddTemplate;
