import { useQuery } from "@tanstack/react-query";
import helpDocsService from "../../services/help-docs.service";
import Loader2 from "../utilities/Loader2";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { NeoGenHelpDocs } from "../../typings/api";
import DeleteFaq from "./modals/delete-faq";
import { useNavigate } from "react-router-dom";
import PageDescription from "../../layout/page-description";
import TableNeogen from "../../layout/table-neogen";
import packageSectionRulesService from "../../services/package-section-rules.service";
import helpDocsTemplateService from "../../services/help-docs-template.service";
import helpDocsTemplateItemsService from "../../services/help-docs-template-items.service";
import { Select } from "../../layout/form/select-input";
import PrintPre from "../../layout/print-pre";
import AddTemplate from "./modals/add-template";

export default function Faq() {
    const [showDeleteFaq, setShowDeleteFaq] = useState(false);
    const [existing, setExisting] = useState<null | NeoGenHelpDocs>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
    const [helpDocs, setHelpDocs] = useState<NeoGenHelpDocs[]>([]);
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
    const [editing, setEditing] = useState<boolean>(false);

    const navigate = useNavigate();
    const helpDocsQuery = useQuery(["HelpDocs", "sorted"], async () => {
        const response = await helpDocsService.getAll();
        if (response) {
            return response.data.sort((a, b) => {
                if ((a.order ?? 0) > (b.order ?? 0)) return 1;
                if ((a.order ?? 0) < (b.order ?? 0)) return -1;

                return 0;
            });
        }
    });
    const helpDocsTemplatesQuery = useQuery(["HelpDocsTemplates"], async () => {
        const response = await helpDocsTemplateService.getAll();
        return response?.data ?? [];
    });
    const helpDocsTemplateItemsQuery = useQuery(["HelpDocsTemplateItems", selectedTemplate], async () => {
        const response = await helpDocsTemplateItemsService.getFilteredWhere({
            templateId: selectedTemplate,
        });
        return response?.data ?? [];
    });
    const rulesQuery = useQuery(["packageSectionRules"], async () => {
        const response = await packageSectionRulesService.getAll();
        return response?.data ?? [];
    });
    useEffect(() => {
        // Create an array of helpDocs that are in the templateItemsQuery
        const helpDocTemplateSelected = helpDocsTemplateItemsQuery.data?.map((e) => e.helpDocId) ?? [];
        console.log({ helpDocTemplateSelected });
        const helpDocsFiltered = helpDocsTemplateItemsQuery.data?.map((e) => {
            return helpDocsQuery.data?.find((h) => h.id === e.helpDocId);
        });
        console.log({
            helpDocsFiltered,
            helpDocsQuery: helpDocsQuery.data,
            helpDocsTemplateItemsQuery: helpDocsTemplateItemsQuery.data,
        });
        setHelpDocs((helpDocsFiltered as NeoGenHelpDocs[]) ?? []);
    }, [helpDocsQuery.data, helpDocsTemplateItemsQuery.data]);

    return (
        <div>
            <div>
                {helpDocsQuery.isLoading ? (
                    <Loader2 />
                ) : (
                    <>
                        <PageDescription
                            title="Sections"
                            description="Package Sections"
                            buttons={[
                                {
                                    label: "Add Section",
                                    onClick: () => {
                                        navigate("/faq/new");
                                    },
                                },
                                // Add a template
                                {
                                    label: "Add Template",
                                    onClick: () => {
                                        setEditing(false);
                                        setShowAddTemplateModal(true);
                                    },
                                },
                                // Edit Template (Basically the same as add template)
                                {
                                    label: "Edit Template",
                                    onClick: () => {
                                        setEditing(true);
                                        setShowAddTemplateModal(true);
                                    },
                                },
                            ]}
                        />
                        <div className="mb-5">
                            <Select
                                label="Template"
                                options={
                                    helpDocsTemplatesQuery.data?.map((e) => {
                                        return {
                                            label: e.name ?? "",
                                            value: e.id ?? -1,
                                        };
                                    }) ?? []
                                }
                                onChange={(e) => {
                                    console.log(e);
                                    setSelectedTemplate(Number(e) ?? null);
                                }}
                                value={selectedTemplate}
                            />
                        </div>
                        <TableNeogen
                            entries={
                                helpDocs.map((e: any, idx: number) => {
                                    console.log(e);
                                    if (e) {
                                        return {
                                            id: e.id,
                                            title: e.title,
                                            tags: e.tags,
                                            updated: e.updated,
                                            created: e.created,
                                            order: idx,
                                        };
                                    } else {
                                        return {
                                            id: -1,
                                            title: "Error",
                                            tags: "Error",
                                            updated: new Date(),
                                            created: new Date(),
                                            order: idx,
                                        };
                                    }
                                }) ?? []
                            }
                            formatters={[
                                { field: "updated", type: "DateTime" },
                                { field: "created", type: "DateTime" },
                            ]}
                            actions={[
                                {
                                    label: "Edit",
                                    icon: "fas fa-edit",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);
                                            navigate("/faq/edit/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    labelActive: (id) => {
                                        return (
                                            "Rules (" + rulesQuery.data?.filter((i) => i.helpDoc === id).length + ")"
                                        );
                                    },
                                    label: "",
                                    icon: "fas fa-gavel",
                                    type: "info",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            navigate("/package-section-rules/" + entry.id);
                                        }
                                    },
                                },
                                {
                                    label: "",
                                    icon: "fas fa-caret-up",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            // Go through the helpdocs and find the one with the index of e
                                            for (let i = 0; i < (helpDocsQuery.data?.length ?? 0); i++) {
                                                if (helpDocsQuery.data?.[i]?.id === e) {
                                                    const temp = helpDocsQuery?.data?.[i - 1];
                                                    if (temp) {
                                                        temp.order = i;
                                                        helpDocsService.update(temp.id, temp);
                                                        entry.order = i - 1;
                                                        helpDocsService.update(entry.id, entry);
                                                        helpDocsQuery.refetch();
                                                    }
                                                }
                                            }
                                            helpDocsQuery.refetch();
                                        }
                                    },
                                },
                                {
                                    label: "",
                                    icon: "fas fa-caret-down",
                                    onClick: async (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            for (let i = 0; i < (helpDocsQuery.data?.length ?? 0); i++) {
                                                if (helpDocsQuery.data?.[i]?.id === e) {
                                                    const temp = helpDocsQuery?.data?.[i + 1];
                                                    if (temp) {
                                                        entry.order = i + 1;
                                                        helpDocsService.update(entry.id, entry);
                                                        temp.order = i;
                                                        helpDocsService.update(temp.id, temp);

                                                        await helpDocsQuery.refetch();

                                                        break;
                                                    }
                                                }
                                            }
                                            helpDocsQuery.refetch();
                                        }
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: "fas fa-trash",
                                    type: "danger",
                                    onClick: (e) => {
                                        const entry = helpDocsQuery.data?.find((h) => h.id === e);
                                        if (entry) {
                                            setExisting(entry);

                                            setShowDeleteFaq(true);
                                        }
                                    },
                                },
                            ]}
                        />
                    </>
                )}
            </div>

            <DeleteFaq show={showDeleteFaq} hide={() => setShowDeleteFaq(false)} existingEntry={existing} />
            <AddTemplate
                show={showAddTemplateModal}
                close={() => setShowAddTemplateModal(false)}
                template={
                    editing
                        ? selectedTemplate
                            ? helpDocsTemplatesQuery.data?.find((e) => e.id === selectedTemplate)
                            : undefined
                        : undefined
                }
            />
        </div>
    );
}
