import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../auth/use-auth";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useEffect, useMemo, useRef, useState } from "react";
import { groupBy, orderBy, startCase } from "lodash";
import ButtonNeoGen from "../../layout/button-neogen";
import {
    UpdateCompanyMandateAssignment,
    updateCompanyMandateAssignment,
} from "../../company-mandate-assignments/actions/update-company-mandate-assignment";
import { useMandates } from "../hooks/use-mandates";
import { useMandateMandateEffects } from "../../mandate-mandate-effects/hooks/use-mandate-mandate-effects";
import { useIndustryGroups } from "../../industry-groups/hooks/use-industry-groups";
import { useMandateEffects } from "../../mandate-effects/hooks/use-mandate-effects";
import { useVirtualMandateAssignments } from "../../company-mandate-assignments/hooks/use-company-mandate-assignments";
import { MandateEffectModal } from "../../mandate-effects/components/mandate-effect-modal";
import { ViewportList } from "react-viewport-list";
import React from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import SelectNeoGen from "../../layout/select-neogen";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { ResponsiveEllipsis } from "../../layout/text";
import { Mandate } from "../domain/mandate";
import { MandateMandateEffect } from "../../mandate-mandate-effects/domain/mandate-mandate-effect";
import { IndustryGroup } from "../../industry-groups/domain/industry-group";
import { CompanyMandateAssignment } from "../../company-mandate-assignments/domain/company-mandate-assignment";
import { MandateEffect } from "../../mandate-effects/domain/mandate-effect";
import { Form } from "../../layout/form/form";
import { SelectField } from "../../layout/form/select-field";
import { useForm } from "../../hooks/useForm";
import { z } from "zod";

const schema = z.object({
    states: z.array(z.string()).nullish(),
    effectIds: z.array(z.number()).nullish(),
    industryGroupIds: z.array(z.number()).nullish(),
    quarters: z.array(z.number()).nullish(),
});

export type MandateFilters = z.infer<typeof schema>;

export const MandateRow = ({
    mandate,
    mandateEffect,
    mandateMandateEffect,
    industryGroup,
    companyMandateAssignment,
    onMandateMandateEffectClick,
}: {
    mandate?: Mandate;
    mandateEffect?: MandateEffect;
    mandateMandateEffect?: MandateMandateEffect;
    industryGroup?: IndustryGroup;
    companyMandateAssignment: CompanyMandateAssignment;
    onMandateMandateEffectClick: (mandateMandateEffectId: number) => void;
}) => {
    return (
        <Tr key={companyMandateAssignment.id}>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        <Link to={`/mandates/${mandate?.id}`}>{mandate?.name}</Link>
                    </div>
                </div>
            </Td>
            <Td>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ color: "gray", fontSize: 14 }}>
                        {mandate?.state && <div>State: {mandate?.state}</div>}
                        {mandate?.county && <div>County: {mandate?.county}</div>}
                        {mandate?.city && <div>City: {mandate?.city}</div>}
                    </div>
                </div>
            </Td>
            <Td>
                <div className="flex flex-col" style={{ color: "gray" }}>
                    <div>From: {mandate?.date && format(mandate.date, "yyyy-MM-dd")}</div>
                    <div>To: {mandate?.until && format(mandate.until, "yyyy-MM-dd")}</div>
                </div>
            </Td>
            <Td style={{ verticalAlign: "top" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ fontWeight: 500 }}>
                        {startCase(mandateEffect?.effectName || "")} {industryGroup ? `(${industryGroup.name})` : null}
                    </div>
                    <div
                        style={{
                            fontSize: 13,
                            color: "gray",
                            ...(mandateMandateEffect?.id ? { cursor: "pointer" } : {}),
                        }}
                        onClick={
                            mandateMandateEffect?.id
                                ? () => onMandateMandateEffectClick(mandateMandateEffect.id)
                                : undefined
                        }
                    >
                        <ResponsiveEllipsis
                            text={mandateMandateEffect?.description || ""}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                        />
                    </div>
                </div>
            </Td>
        </Tr>
    );
};

export const MandateLookupPage = () => {
    const auth = useAuth();
    const authToken = auth.expectAuthToken();
    const [mandateMandateEffectId, setMandateEffectId] = useState<number | undefined>();
    const ref = useRef<HTMLDivElement | null>(null);
    const [filters, setFilters] = useState<MandateFilters>({});
    const [expanded, setExpanded] = useState(JSON.parse(localStorage.getItem("expanded") || "{}"));
    const [sortedBy, setSortedBy] = useState<string>("name");
    const [groupedBy, setGroupedBy] = useState<string>("none");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    useEffect(() => {
        localStorage.setItem("expanded", JSON.stringify(expanded));
    }, [expanded]);

    const virtualMandateAssignmentsQuery = useVirtualMandateAssignments({
        authToken,
        filters: {
            effectIds: filters.effectIds,
            industryGroupIds: filters.industryGroupIds,
            states: filters.states,
            quarters: {
                q12020: filters.quarters?.includes(1),
                q22020: filters.quarters?.includes(2),
                q32020: filters.quarters?.includes(3),
                q42020: filters.quarters?.includes(4),
                q12021: filters.quarters?.includes(5),
                q22021: filters.quarters?.includes(6),
                q32021: filters.quarters?.includes(7),
                q42021: filters.quarters?.includes(8),
            },
        },
    });
    const companyMandateAssignments = useMemo(
        () => virtualMandateAssignmentsQuery.data || [],
        [virtualMandateAssignmentsQuery.data],
    );

    const mandateIds = companyMandateAssignments.map((assignment) => assignment.mandateId);
    const mandatesQuery = useMandates(
        { authToken, filters: { where: { id: { inq: mandateIds } } } },
        {
            enabled: virtualMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandates = useMemo(() => mandatesQuery.data || [], [mandatesQuery.data]);

    const allMandateEffectsQuery = useMandateEffects({ authToken });
    const allMandateEffects = useMemo(() => allMandateEffectsQuery.data || [], [allMandateEffectsQuery.data]);

    const allIndustryGroupsQuery = useIndustryGroups({ authToken });
    const allIndustryGroups = useMemo(() => allIndustryGroupsQuery.data || [], [allIndustryGroupsQuery.data]);

    const mandateEffectIds = companyMandateAssignments.map((a) => a.mandateEffectId);
    const mandateEffectsQuery = useMandateEffects(
        {
            authToken,
            filters: { where: { id: { inq: mandateEffectIds } } },
        },
        {
            enabled: virtualMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateEffects = useMemo(() => mandateEffectsQuery.data || [], [mandateEffectsQuery.data]);

    const mandateMandateEffectsQuery = useMandateMandateEffects(
        {
            authToken,
            filters: { where: { mandateId: { inq: mandateIds, mandateEffectId: { inq: mandateEffectIds } } } },
        },
        {
            enabled: virtualMandateAssignmentsQuery.isSuccess && companyMandateAssignments.length > 0,
        },
    );
    const mandateMandateEffects = useMemo(
        () => mandateMandateEffectsQuery.data || [],
        [mandateMandateEffectsQuery.data],
    );

    const i = companyMandateAssignments.filter((a) => !!a.industryGroupId);

    const industryGroupIds = i.map((assignment) => assignment.industryGroupId);
    const industryGroupsQuery = useIndustryGroups(
        {
            authToken,
            filters: { where: { id: { inq: industryGroupIds } } },
        },
        {
            enabled: virtualMandateAssignmentsQuery.isSuccess && i.length > 0,
        },
    );
    const industryGroups = useMemo(() => industryGroupsQuery.data || [], [industryGroupsQuery.data]);

    const quarterOptions = [
        { label: "2020 Q1", value: 1 },
        { label: "2020 Q2", value: 2 },
        { label: "2020 Q3", value: 3 },
        { label: "2020 Q4", value: 4 },
        { label: "2021 Q1", value: 5 },
        { label: "2021 Q2", value: 6 },
        { label: "2021 Q3", value: 7 },
        { label: "2021 Q4", value: 8 },
    ];

    const rows = useMemo(
        () =>
            orderBy(
                companyMandateAssignments.map((cma) => {
                    return {
                        companyMandateAssignment: cma,
                        mandate: mandates.find((m) => m.id === cma.mandateId),
                        mandateEffect: mandateEffects.find((me) => me.id === cma.mandateEffectId),
                        mandateMandateEffect: mandateMandateEffects.find(
                            (me) => me.mandateId === cma.mandateId && me.mandateEffectId === cma.mandateEffectId,
                        ),
                        industryGroup: industryGroups.find((ig) => ig.id === cma.industryGroupId),
                    };
                }),
                (row) => {
                    if (sortedBy === "name") {
                        return row.mandate?.name?.toLocaleLowerCase();
                    }
                    if (sortedBy === "date") {
                        return row.mandate?.date;
                    }
                    if (sortedBy === "state") {
                        return row.mandate?.state?.toLocaleLowerCase();
                    }
                    if (sortedBy === "effect") {
                        return row.mandateEffect?.effectName.toLowerCase();
                    }
                    return row.mandate?.name;
                },
                sortDirection,
            ),
        [
            companyMandateAssignments,
            industryGroups,
            mandateEffects,
            mandateMandateEffects,
            mandates,
            sortedBy,
            sortDirection,
        ],
    );

    const mandateMandateEffectToView = useMemo(
        () => mandateMandateEffects.find((mme) => mme.id === mandateMandateEffectId),
        [mandateMandateEffects, mandateMandateEffectId],
    );

    const groupedRows =
        groupedBy !== "none"
            ? groupBy(rows, (row) => {
                  if (groupedBy === "date") {
                      return format(row.mandate?.date || new Date(), "yyyy-MM-dd");
                  }
                  if (groupedBy === "name") {
                      return row.mandate?.name;
                  }
                  if (groupedBy === "effect") {
                      return row.mandateEffect?.effectName;
                  }
              })
            : [];

    const effectOptions = orderBy(
        allMandateEffects.map((m) => ({ value: m.id, label: `${m.effectName}` })),
        (o) => o.label,
    );

    const industryGroupOptions = orderBy(
        allIndustryGroups.map((ig) => ({ value: ig.id, label: `${ig.name}` })),
        (o) => o.label,
    );

    const form = useForm({ schema, defaultValues: filters });

    const handleSubmit = form.handleSubmit((data: MandateFilters) => {
        setFilters(data);
    });

    return (
        <>
            {mandateMandateEffectToView && (
                <MandateEffectModal
                    mandateMandateEffect={mandateMandateEffectToView}
                    onClose={() => setMandateEffectId(undefined)}
                    mandateEffect={
                        allMandateEffects.find((m) => m.id === mandateMandateEffectToView.mandateEffectId)
                            ?.effectName || ""
                    }
                />
            )}
            <div style={{ padding: 12 }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Mandate Lookup</div>
                    <div className="flex flex-row gap-1">
                        <div className="flex flex-row items-center gap-1">
                            <div>Group By</div>
                            <div>
                                <SelectNeoGen
                                    value={groupedBy}
                                    options={[
                                        { id: "none", name: "None" },
                                        { id: "name", name: "Name" },
                                        { id: "date", name: "Date" },
                                        { id: "effect", name: "Effect" },
                                    ]}
                                    onChange={(val) => {
                                        return setGroupedBy(val as string);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-1">
                            <div>Sort By</div>
                            <div style={{ minWidth: 100 }}>
                                <SelectNeoGen
                                    value={sortedBy}
                                    options={[
                                        { id: "name", name: "Name" },
                                        { id: "date", name: "Date" },
                                        { id: "state", name: "State" },
                                        { id: "effect", name: "Effect" },
                                    ]}
                                    onChange={(val) => {
                                        return setSortedBy(val as string);
                                    }}
                                />
                            </div>
                            <div style={{ minWidth: 80 }}>
                                <SelectNeoGen
                                    value={sortDirection}
                                    options={[
                                        { id: "asc", name: "Asc" },
                                        { id: "desc", name: "Desc" },
                                    ]}
                                    onChange={(val) => {
                                        return setSortDirection(val as any);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: 12 }}>
                    <Form onSubmit={handleSubmit}>
                        <div className="flex flex-row gap-4">
                            <div className="flex-1">
                                <SelectField
                                    label="States"
                                    {...form.getFieldProps("states")}
                                    options={[
                                        { value: "Florida", label: "Florida" },
                                        { value: "Texas", label: "Texas" },
                                    ]}
                                    isMultiple
                                />
                            </div>
                            <div className="flex-1">
                                <SelectField
                                    label="Effects"
                                    {...form.getFieldProps("effectIds")}
                                    options={effectOptions}
                                    isMultiple
                                />
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex-1">
                                <SelectField
                                    label="Industry Groups"
                                    {...form.getFieldProps("industryGroupIds")}
                                    options={industryGroupOptions}
                                    isMultiple
                                />
                            </div>
                            <div className="flex-1">
                                <SelectField
                                    label="Quarters"
                                    {...form.getFieldProps("quarters")}
                                    options={quarterOptions}
                                    isMultiple
                                />
                            </div>
                        </div>
                        <ButtonNeoGen type="submit">Apply</ButtonNeoGen>
                    </Form>
                </div>
                <div ref={ref} style={{ height: "calc(100vh - 380px)", overflow: "auto" }}>
                    <Table style={{ tableLayout: "fixed" }}>
                        <THead>
                            <Tr>
                                <Th style={{ width: 220 }}>Mandate</Th>
                                <Th style={{ width: 250 }}>Location</Th>
                                <Th style={{ width: 220 }}>Date</Th>
                                <Th>Effect</Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {groupedBy !== "none" && (
                                <>
                                    {Object.keys(groupedRows).map((key) => {
                                        const rowsToShow = (groupedRows as any)?.[key] || [];
                                        const isExpanded = expanded[key] === true;
                                        return (
                                            <React.Fragment key={key}>
                                                {rowsToShow.length === 0 && (
                                                    <Tr>
                                                        <Td colSpan={5} style={{ textAlign: "center" }}>
                                                            No Mandate Effects
                                                        </Td>
                                                    </Tr>
                                                )}
                                                {rowsToShow.length > 0 && (
                                                    <React.Fragment key={key}>
                                                        <Tr>
                                                            <Td
                                                                colSpan={4}
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setExpanded({
                                                                        ...expanded,
                                                                        [key]: isExpanded ? false : !expanded[key],
                                                                    });
                                                                }}
                                                            >
                                                                <div className="flex flex-row gap-1">
                                                                    <div style={{ width: 20 }}>
                                                                        {!isExpanded ? (
                                                                            <ChevronRightIcon />
                                                                        ) : (
                                                                            <ChevronDownIcon />
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <b>
                                                                            {key} ({rowsToShow.length})
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </Td>
                                                        </Tr>
                                                        {isExpanded && (
                                                            <ViewportList
                                                                key={key}
                                                                viewportRef={ref}
                                                                items={rowsToShow}
                                                                renderSpacer={({ ref, style }) => (
                                                                    <Tr ref={ref} style={style} />
                                                                )}
                                                                initialPrerender={25}
                                                                overscan={10}
                                                            >
                                                                {(row: any) => {
                                                                    return (
                                                                        <MandateRow
                                                                            key={row.companyMandateAssignment.id}
                                                                            companyMandateAssignment={
                                                                                row.companyMandateAssignment
                                                                            }
                                                                            industryGroup={row.industryGroup}
                                                                            mandate={row.mandate}
                                                                            mandateEffect={row.mandateEffect}
                                                                            mandateMandateEffect={
                                                                                row.mandateMandateEffect
                                                                            }
                                                                            onMandateMandateEffectClick={(id) =>
                                                                                setMandateEffectId(id)
                                                                            }
                                                                        />
                                                                    );
                                                                }}
                                                            </ViewportList>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                            {groupedBy === "none" && (
                                <>
                                    {rows.length === 0 && (
                                        <Tr>
                                            <Td colSpan={4} style={{ textAlign: "center" }}>
                                                No Mandate Effects
                                            </Td>
                                        </Tr>
                                    )}
                                    {rows.length > 0 && (
                                        <ViewportList
                                            viewportRef={ref}
                                            items={rows}
                                            renderSpacer={({ ref, style }) => <Tr ref={ref} style={style} />}
                                            initialPrerender={25}
                                            overscan={10}
                                        >
                                            {(row: any) => {
                                                return (
                                                    <MandateRow
                                                        key={row.companyMandateAssignment.id}
                                                        companyMandateAssignment={row.companyMandateAssignment}
                                                        industryGroup={row.industryGroup}
                                                        mandate={row.mandate}
                                                        mandateEffect={row.mandateEffect}
                                                        mandateMandateEffect={row.mandateMandateEffect}
                                                        onMandateMandateEffectClick={(id) => setMandateEffectId(id)}
                                                    />
                                                );
                                            }}
                                        </ViewportList>
                                    )}
                                </>
                            )}
                        </TBody>
                    </Table>
                </div>
            </div>
        </>
    );
};
